<template>
  <div class="bodycont">

    <comHeader />
    <div class="infodiv">
      <navTitle :navData='navData' />
      <div style="display: flex;justify-content: space-between;">
     <!-- <div v-if="getaid">
        <div class="news_list">
          <span @click="changindex(item.code)" :class="cindex==item.code?'cur':''"
            v-for="(item,index) in typeList">{{item.value}}</span>
        </div>

      </div> -->
      <div v-if="!isDetails">
        <div style="height: 70vh;width: 960px;">
          <div style="margin: 100px auto 0;width: 100px;">
            <img style="width: 100px;height: 100px;" src="../../assets/images/datano.png" />
          </div>
          <p style="text-align: center;">暂无详情数据</p>
        </div>
      </div>
      <div v-if="isDetails" style="width: 1190px">

        <div class="topdiv">
          <div class="topimg">
            <div style="width:400px;height:400px">
              <img v-if="msrUrlPicture==0" :src="mainImgUrl?base_url+mainImgUrl:nodata" style="width:100%;height:100%">
              <video v-if="msrUrlPicture==1"  id="media" class="media" height="100%" width="100%" controls :src="base_url+mainImgUrl"></video>
            </div>
            <div>
              <i style="font-size: 30px;display: inline-block;position: relative;top: 40px;cursor: pointer;"
                class="el-icon-arrow-left" @click="imgLeft()"></i>
              <ul class="Img_ul">
                <li v-for="(item,index) in imgUrlList" :key="index" class="Img_li" :style="imgStyle"
                  @mouseover="changeImg(item, index)" @click="changeImg(item, index)">
                  <img v-if="item.msrUrlPicture==0" :class="index === imgActiveIndex ? 'img_activeBorder' : ''" :src="base_url+item.msrUrlStatic"
                    style="width:50px;height:50px">
                  <video
                  style="width:50px;height:50px"
                  v-if="item.msrUrlPicture==1"
                  :src="base_url+item.msrUrlStatic"></video>

                </li>
              </ul>
              <i style="font-size: 30px;display: inline-block;position: relative;left: 370px;top: -42px;cursor: pointer;"
                class="el-icon-arrow-right" @click="imgRight()"></i>
            </div>
          </div>

          <div class="bassMess counter-box py-2 my-2">

            <div class="messTable">
              <div class="tit" v-if="dataInfo.adName">
                <h4 class="fleft" id="h_name">{{dataInfo.adName}}
                  <span class='statusdiv' v-if="dataInfo.isConversion">{{dataInfo.isConversion==true?'已转化':''}}</span>
                </h4>
              </div>
              <div class="tit" v-if="dataInfo.aName">
                <h4 class="fleft" id="h_name">{{dataInfo.aName}}
                  <span class='statusdiv' v-if="dataInfo.isConversion">{{dataInfo.isConversion==true?'已转化':''}}</span>
                </h4>
              </div>

              <div class="mdiv" v-if="!gettype">

                <span class="company" v-if="dataInfo.patentCopyrightTitle"><span class="tit_small">标题：</span>{{dataInfo.patentCopyrightTitle}}</span>
                <span class="company" v-if="dataInfo.licenseNum"><span class="tit_small">标题：</span>{{dataInfo.licenseNum}}</span>
                <span class="company" v-if="dataInfo.oaMainAchievements"><span class="tit_small">标题：</span>{{dataInfo.oaMainAchievements}}</span>
                <span class="company" v-if="dataInfo.tsSecretPoints"><span class="tit_small">标题：</span>{{dataInfo.tsSecretPoints}}</span>
                <span class="company" v-if="dataInfo.projectTitle"><span class="tit_small">标题：</span>{{dataInfo.projectTitle}}</span>

              </div>
              <div class="mdiv" v-if="dataInfo.resulttype">
                <span class="tit_small">成果类型：</span>
                <span class="company">{{dataInfo.resulttype}}</span>
              </div>
              <div class="mdiv" v-if="dataInfo.mainIpcClassificationNumber">
                <span class="tit_small">专利号：</span>
                <span class="company">{{dataInfo.mainIpcClassificationNumber}}</span>
              </div>
              <div class="mdiv" v-if="dataInfo.licenseNum">
                <span class="tit_small">软著登记号：</span>
                <span class="company">{{dataInfo.licenseNum}}</span>
              </div>
              <div class="mdiv" v-if="dataInfo.achAddress">
                <span class="tit_small">单位名称：</span>
                <span class="company">{{dataInfo.achAddress}}</span>
              </div>


              <div class="mdiv">
                <span class="tit_small">学科领域：</span>
                <span class="company">{{dataInfo.subject}}</span>
              </div>
              <div class="mdiv">
                <span class="tit_small">应用领域：</span>
                <span class="company">{{dataInfo.application}}</span>
              </div>
              <div class="mdiv" v-if="dataInfo.amCooperationName">
                <span class="tit_small">合作方式：</span>
                <span class="company">{{dataInfo.amCooperationName}}</span>
              </div>

              <div class="mdiv"  v-if="amType!=0">
                <span class="tit_small">成果关键词：</span>
                <span class="company" v-if="dataInfo.adResultsKeword">{{dataInfo.adResultsKeword}}</span>
                <span class="company" v-if="dataInfo.cdResultsKeword">{{dataInfo.cdResultsKeword}}</span>
                <span class="company" v-if="dataInfo.tsResultsKeword">{{dataInfo.tsResultsKeword}}</span>
                <span class="company" v-if="dataInfo.oaKeyWords">{{dataInfo.oaKeyWords}}</span>
                <span class="company" v-if="dataInfo.sedResultsKeword">{{dataInfo.sedResultsKeword}}</span>
                <span class="company" v-if="dataInfo.proResultsKeword">{{dataInfo.proResultsKeword}}</span>

              </div>


              <div v-if="gettype=='service'">
                     <div class="mdiv ">
                       <span class="tit_small">服务范围：</span>
                       <span class="company">{{dataInfo.sedApplicationRange}}</span>
                     </div>
                     <div class="mdiv ">
                       <span class="tit_small">服务年限：</span>
                       <span class="company">{{dataInfo.sedLicenseLife}}年</span>
                     </div>


                  <div class="mbox" >
                    <div class="mdiv mdiv1">
                      <span class="tit_small">服务类别：</span>
                      <span class="company">{{dataInfo.sedTypeName}}</span>
                    </div>
                     <div class="mdiv mdiv1">
                       <span class="tit_small">转让价格：</span>
                       <span class="company">{{dataInfo.sedTransferValue}}元</span>
                     </div>
                     <div class="mdiv mdiv1">
                       <span class="tit_small">服务价值：</span>
                       <span class="company">{{dataInfo.sedPatentValue}}元</span>
                     </div>
                 </div>
                </div>
                <div v-if="gettype=='park'">
                  <div class="mdiv ">
                    <span class="tit_small">产品使用场景：</span>
                    <span class="company">{{dataInfo.proApplicationRange}}</span>
                  </div>
                  <div class="mdiv ">
                      <span class="tit_small">受让方：</span>
                      <span class="company">{{dataInfo.proTransferee}}</span>
                    </div>
                    <div class="mbox" >
                      <div class="mdiv mdiv1">
                        <span class="tit_small">产品所属单位：</span>
                        <span class="company">{{dataInfo.proProductOwner}}</span>
                      </div>
                       <div class="mdiv mdiv1">
                         <span class="tit_small">产品状态：</span>
                         <span class="company">{{dataInfo.proStatueName}}</span>
                       </div>

                    </div>
                    <div class="mbox" v-if="amType!=0">
                        <div class="mdiv mdiv1">
                          <span class="tit_small">许可年限：</span>
                          <span class="company">{{dataInfo.proLicenseLife}}年
                          </span>
                        </div>
                       <div class="mdiv mdiv1">
                         <span class="tit_small">转让价格：</span>
                         <span class="company">{{dataInfo.proTransferValue}}元</span>
                       </div>
                       <div class="mdiv mdiv1">
                         <span class="tit_small">转让形式：</span>
                         <span class="company">{{dataInfo.proTransferForm}}</span>
                       </div>
                   </div>


                  </div>

              <div class="mbox" v-if="amType!=0">
                <div class="mdiv mdiv1">
                  <span class="tit_small">是否拥有样机：</span>
                  <span class="company">
                     <span v-if="dataInfo.adIsMode==1 || dataInfo.cdIsMode==1||dataInfo.tsIsMode==1 ||dataInfo.oaIsMode==1||dataInfo.sedIsMode==1||dataInfo.proIsMode==1">是</span>
                     <span v-if="dataInfo.adIsMode==0 || dataInfo.cdIsMode==0||dataInfo.tsIsMode==0 ||dataInfo.oaIsMode==0||dataInfo.sedIsMode==0||dataInfo.proIsMode==0">否</span>
                  </span>
                </div>
                <div class="mdiv mdiv1" >
                  <span class="tit_small">是否成果鉴定：</span>
                  <span class="company">
                     <span v-if="dataInfo.adIsAuthenticate==1 || dataInfo.cdIsAuthenticate==1 ||dataInfo.tsIsAuthenticate==1||dataInfo.oaIsAuthenticate==1||dataInfo.sedIsAuthenticate==1||dataInfo.proIsAuthenticate==1">是</span>
                     <span  v-if="dataInfo.adIsAuthenticate==0 || dataInfo.cdIsAuthenticate==0 ||dataInfo.tsIsAuthenticate==0||dataInfo.oaIsAuthenticate==0||dataInfo.sedIsAuthenticate==0||dataInfo.proIsAuthenticate==0">否</span>
                  </span>


                </div>
                <div class="mdiv mdiv1">
                  <span class="tit_small">是否入驻园区：</span>
                  <span class="company">
                     <span v-if="dataInfo.adIsEnter==1 || dataInfo.cdIsEnter==1 ||dataInfo.tsIsEnter==1||dataInfo.oaIsEnter==1||dataInfo.sedIsEnter==1||dataInfo.proIsEnter==1">是</span>
                     <span  v-if="dataInfo.adIsEnter==0 || dataInfo.cdIsEnter==0 ||dataInfo.tsIsEnter==0||dataInfo.oaIsEnter==0||dataInfo.sedIsEnter==0||dataInfo.proIsEnter==0">否</span>
                  </span>
                </div>
              </div>
              <div class="mbox">
                <div class="mdiv mdiv1">
                  <span class="tit_small">成熟度自评：</span>
                  <span class="company">{{dataInfo.mature}}</span>
                </div>
                <div class="mdiv mdiv1">
                  <span class="tit_small">先进度自评：</span>
                  <span class="company">{{dataInfo.advanced}}</span>
                </div>
                <div class="mdiv mdiv1">
                  <span class="tit_small">创新度自评：</span>
                  <span class="company">{{dataInfo.innovate}}</span>
                </div>
              </div>
           </div>

            <p class="btnBox">
              <el-button type="primary" @click="changeleave(1)">联系方式</el-button>
              <!-- <el-button type="primary"  @click="changeleave(2)">我要评论</el-button> -->
            </p>


            <div v-if="isshow">
              <p class="ctitle">评论:</p>
              <el-input type="textarea" :rows="2" placeholder="请输入评论" v-model="textarea">
              </el-input>
              <div class="cbtn">
                <el-button size='mini' @click="changeleave" type="info">关闭</el-button>
                <el-button size='mini' type="primary">提交</el-button>
              </div>
            </div>
          </div>
        </div>

        <div class="infocont">

          <div class="leftContent">

            <div class="counter-box py-2 my-2" v-if="isIndex==1">
              <div class="levelMess jsxmMess">
                <div class="levelTit" v-if="!gettype&&amType!=0">
                  <p>概要</p>
                  <div class="colorBox"></div>
                </div>
                <div class="row showview" deep="4" v-if='amType==1||amType==2||amType==3'>
                  <div class="mdiv" v-if="dataInfo.applicationNum">
                    <span class="tit_small">申请号：</span>
                    <span class="company">{{dataInfo.applicationNum}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.applicant">
                    <span class="tit_small">申请人：</span>
                    <span class="company">{{dataInfo.applicant}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.publicAccount">
                    <span class="tit_small">公开号：</span>
                    <span class="company">{{dataInfo.publicAccount}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.openDay">
                    <span class="tit_small">公开日：</span>
                    <span class="company">{{dataInfo.openDay}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.applicationDate">
                    <span class="tit_small">申请日：</span>
                    <span class="company">{{dataInfo.applicationDate}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.inventor">
                    <span class="tit_small">专利发明人：</span>
                    <span class="company">{{dataInfo.inventor}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.claimsNumber">
                    <span class="tit_small">权利要求数：</span>
                    <span class="company">{{dataInfo.claimsNumber}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.siblingsNumber">
                    <span class="tit_small">同族次数：</span>
                    <span class="company">{{dataInfo.siblingsNumber}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.beCitedNumber">
                    <span class="tit_small">被引用次数：</span>
                    <span class="company">{{dataInfo.beCitedNumber}}</span>
                  </div>
                  <div class="mdiv"  v-if="dataInfo.quoteNumber">
                    <span class="tit_small">引用数量：</span>
                    <span class="company">{{dataInfo.quoteNumber}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.pledgesNumber">
                    <span class="tit_small">质押次数：</span>
                    <span class="company">{{dataInfo.pledgesNumber}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.licensesNumber">
                    <span class="tit_small">许可次数：</span>
                    <span class="company">{{dataInfo.licensesNumber}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.transfersNumber">
                    <span class="tit_small">转让次数：</span>
                    <span class="company">{{dataInfo.transfersNumber}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.legalEvent">
                    <span class="tit_small">法律事件：</span>
                    <span class="company">{{dataInfo.legalEvent}}</span>
                  </div>
                  <div class="mdiv" v-if="dataInfo.latestLegalName">
                    <span class="tit_small">法律状态：</span>
                    <span class="company">{{dataInfo.latestLegalName}}</span>
                  </div>
                  <div class="mdiv"  v-if="dataInfo.patentValue">
                    <span class="tit_small">专利价值度：</span>
                    <span class="company">{{dataInfo.patentValue}}</span>
                  </div>
                </div>

                <div class="row showview" deep="4" v-if='amType==4'>
                  <div class="mdiv">
                    <span class="tit_small">著作证书号：</span>
                    <span class="company">{{dataInfo.certificateNo}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">出版日期：</span>
                    <span class="company">{{dataInfo.publicationDate}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">所属单位：</span>
                    <span class="company">{{dataInfo.affiliatedUnit}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">著作完成人：</span>
                    <span class="company">{{dataInfo.allFinishers}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">著作类型：</span>
                    <span class="company">{{dataInfo.copyrightTypeName}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">状态：</span>
                    <span class="company">{{dataInfo.stateName}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">是否发表：</span>
                    <span class="company">{{dataInfo.cdIsPublished==1?'是':'否'}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">作品署名：</span>
                    <span class="company">{{dataInfo.cdSignature}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">作品创作性质：</span>
                    <span class="company">{{dataInfo.cdNatureCreationName}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">著作完成地点：</span>
                    <span class="company">{{dataInfo.cdPlaceCreation}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">权力归属方：</span>
                    <span class="company">{{dataInfo.cdAttributionOwnerName}}</span>
                  </div>
                </div>

                <div class="row showview" deep="4" v-if='amType==5'>
                  <div class="mdiv">
                    <span class="tit_small">所属单位：</span>
                    <span class="company">{{dataInfo.tsAffiliatedUnit}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">产生时间：</span>
                    <span class="company">{{dataInfo.tsGenerationTime}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">所属单位：</span>
                    <span class="company">{{dataInfo.tsSecurityTime}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">保密期限：</span>
                    <span class="company">{{dataInfo.allFinishers}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">是否申请专利：</span>
                    <span class="company">{{dataInfo.isownpatent==1?'是':'否'}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">项目阶段：</span>
                    <span class="company">{{dataInfo.tsProjectPhaseName}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">涉密人员：</span>
                    <span class="company">{{dataInfo.tsSecretPersonnel}}</span>
                  </div>
                </div>

                <div class="row showview" deep="4" v-if='amType==7'>
                  <div class="mdiv">
                    <span class="tit_small">所属单位：</span>
                    <span class="company">{{dataInfo.oaPatentNumber}}</span>
                  </div>
                  <div class="mdiv">
                    <span class="tit_small">成立企业名称：</span>
                    <span class="company">{{dataInfo.oaEnterpriseName}}</span>
                  </div>

                </div>
                 <div v-if='dataInfo.proInstruction'>
                 <div class="levelTit">
                   <p>使用介绍</p>
                   <div class="colorBox"></div>
                 </div>
                 <div id="synopsis" class="mt-2 col-666" style="line-height: 28px; padding: 0 30px;font-size: 14px;">
                   <div>{{dataInfo.proInstruction}}</div>
                 </div>
                 </div>
                <div class="levelTit">
                  <p>{{dataInfo.resulttype}}简介</p>
                  <div class="colorBox"></div>
                </div>
                <div id="synopsis" class="mt-2 col-666" style="line-height: 28px; padding: 0 30px;font-size: 14px;">
                  <div v-if="dataInfo.adDetail" v-html='dataInfo.adDetail'></div>
                  <div v-if="dataInfo.cdDetail" v-html='dataInfo.cdDetail'></div>
                  <div v-if="dataInfo.tsOverview" v-html='dataInfo.tsOverview'></div>
                  <div v-if="dataInfo.oaIntroduce" v-html='dataInfo.oaIntroduce'></div>
                  <div v-if="dataInfo.sedDetail" v-html='dataInfo.sedDetail'></div>
                  <div v-if="dataInfo.proDetail" v-html='dataInfo.proDetail'></div>
                  <div v-if="dataInfo.projectDetail" v-html='dataInfo.projectDetail'></div>
                </div>

              </div>

            </div>


          </div>

          </div>
          <div class="datacont datacontV" v-if="dataList.length>0">

            <div class="levelTit">
              <p>知识产权清单</p>
              <div class="colorBox"></div>
            </div>
            <div class="linedata" >
                  <div class="infodiv1">
                      <span style="width: 50%;">标题</span>
                      <span>类型</span>
                      <span>创建时间</span>
                      <span>操作</span>
                  </div>
                  <div v-for='(item,index) in dataList' :class="mindex==index?'activecur':''" >
                      <span style="width: 50%;">{{item.name}}</span>
                      <span>{{item.amTypeName}}</span>
                      <span>{{item.createTime}}</span>
                      <span @click="changeDataV(item,index)">查看详情</span>
                  </div>
            <!--  <span class="iname" :class="mindex==index?'activecur':''" v-for="(item,index) in dataList"
              @click="changeDataV(item,index)">{{item.name}}</span> -->

            </div>
        </div>

        <div style="margin-bottom: 20px;">
          <div class="levelTit">
            <p>发表评论</p>
            <div class="colorBox"></div>
          </div>
          <div class="msgdiv">
            <div class="info">
              <img src="../../assets/images/himg.png" />
              <el-input type="textarea" resize='none' :rows="3" placeholder="请输入评论" v-model="ureContent">
              </el-input>
            </div>
            <div class="infobtn">
              <el-button :type="ureContent?'primary':'info'" @click="stbView()">发表</el-button>
            </div>
          </div>
          <div>

          </div>
          <div v-if="messageList.length>0">
            <h3>评论列表</h3>
            <div class="msgdivsend">
              <div class="info" v-for="item in messageList">
                <img src="../../assets/images/himg.png" />
                <div class="msgtextdiv">
                  <p class="name">{{item.userName}}</p>
                  <p class="msg">{{item.ureContent}}</p>
                  <div class="time">
                    <span>{{item.ureDate}}</span>
                    <span @click="changeMsg(item)">{{item.ischeck?'取消回复':'回复'}}</span>
                  </div>

                  <div style="margin-left:40px ;" v-for="items in item.list">
                    <p class="name">{{items.userName}}</p>
                    <p class="msg">{{items.ureContent}}</p>
                    <div class="time">
                      <span>{{items.ureDate}}</span>
                      <!-- <span @click="changeMsg(item)">{{item.ischeck?'取消回复':'回复'}}</span> -->
                    </div>
                  </div>
                  <div class="msgcont" v-if="item.ischeck">
                    <el-input type="textarea" resize='none' :rows="2" placeholder="回复" v-model="ureContent1">
                    </el-input>
                    <el-button :type="ureContent1?'primary':'info'" @click="stbViews(item.ureNums)">回复</el-button>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
     </div>
    </div>
    <bNav />

    <el-dialog title="联系方式" :visible.sync="dialogVisible" width="30%" :before-close="handelClose">
      <div class="mldiv">
        <span class="tit_small">名称：</span>
        <span class="company" v-if="msgInfo.asName">{{msgInfo.asName}}</span>
      </div>
      <div class="mldiv">
        <span class="tit_small">联系人：</span>
        <span class="company" v-if="msgInfo.asPerson">{{msgInfo.asPerson}}</span>
      </div>
      <div class="mldiv">
        <span class="tit_small">联系电话：</span>
        <span class="company" v-if="msgInfo.asTel">{{msgInfo.asTel}}</span>

      </div>
      <div class="mldiv">
        <span class="tit_small">邮箱：</span>
        <span class="company" v-if="msgInfo.asEmail">{{msgInfo.asEmail}}</span>
      </div>
    </el-dialog>

  <!--  <el-dialog title="提示" :visible.sync="isdialog" width="30%" :before-close="handelClose">
      <div v-if="isstatus==1">
        <p style="margin-bottom: 10px;">备注:</p>
        <el-input type="textarea" v-model="form.desc"></el-input>
        <div style="margin:20px auto 0;text-align: right;">
          <el-button @click="handelClose">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </div>
      </div>
      <div v-if="isstatus==2">
        已经发布评论请等待回复
      </div>

    </el-dialog> -->

  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import {
    swiper,
    swiperSlide
  } from "vue-awesome-swiper";
  import "swiper/css/swiper.min.css";
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      navTitle,
      swiper,
      swiperSlide,

    },
    data() {
      return {
        msrUrlPicture:0,
        typeList: [],
        form: {},
        dialogVisible: false,
        isdialog: false,
        isstatus: 1,
        isIndex: 1,
        isshow: false,
        textarea: '',
        navData: [
          // {
          //   name: '成果展示',
          //   url: 'resultsinfo',
          // },
          // {
          //   name: '详情页',
          //   url: 'resultDetails',
          // },
        ],
        date: new Date(),
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true,
          },

          loop: true,
          effect: "cube",
          cube: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          },
        },
        nodata:require('../../assets/images/infonodata.png'),
        slideList: [{
            id: "42",
            img: require('../../assets/images/head-bg.png'),
          },
          {
            id: "45",
            img: require('../../assets/images/head-bg.png'),
          },
        ],
        mainImgUrl: '',
        imgUrlList: [],
        imgActiveIndex: 0, // 当前移动图片的索引值
        imgDistance: 0, // 移动的距离
        allDistance: 0, // 总移动距离
        amType: '',
        aId: '',
        dataInfo: {},
        base_url: api.base_url,
        gettype: '',

        ureContent: '', //评论
        ureContent1: '', //评论
        messageList: [

        ], //评论列表
        getaid: '',
        cindex: 1,
        dataList: [],
        mindex: -1,
        isDetails: true,
        msgInfo:{},
        imgSrc:'',
      }
    },
    computed: {
      imgStyle() {
        return {
          transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
        }
      }
    },
    created() {
      this.navData = [{
          name: this.$route.query.name,
          url: this.$route.query.url,
        },
        {
          name: '详情页',
          url: 'resultDetails',
        },
      ]
    },
    watch:{
      aId(val,old){
        if(val!=old){
           console.log(val,'huodexinde')
           this.getDetails()
        }
      }
    },
    mounted() {
      this.getaid = this.$route.query.aid
      if (this.getaid) {
        this.getTypeList()
        this.getList()
      }
      this.amType = this.$route.query.amType
      this.aId = this.$route.query.id
      this.isConversion = this.$route.query.isConversion
      this.gettype = this.$route.query.type
      if(this.amType==0){
         this.getProjectDetails()
         this.getList()
      }else{
         this.getDetails()
      }
      this.gettelMsg()
    },
    methods: {

      //获取项目成果详情
      getProjectDetails() {
        let data = {}
          data.aId = this.aId
          postRequest(api.queryFontById, data).then(res => {
              this.dataInfo = res.mhProjectDetailVo
              if(this.dataInfo.filesList.length>0){
                this.imgUrlList = this.dataInfo.filesList
                this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
              }


          })
      },
      //获取联系方式
      gettelMsg() {
        let data = {
          asId: 1,
        }
        postRequest(api.aboutUsId, data).then(res => {
           this.msgInfo = res.data
        })
      },
      //获取字典列表
      getTypeList() {
        let data = {
          page: 1,
          limit: 100,
          filterName: '成果类型'
        }
        getRequst(api.sysTypeList, data).then(res => {
          this.typeList = res.page.list || []
        })
      },

      getList() {
        let data = {
          page: 1,
          limit: 20,
          aId:this.aId,
        }
        postRequest(api.MidselectList, data).then(res => {
          if (res.code == 0) {
            this.dataList = res.page.list
          }
        })
      },
      changeDataV(item, index) {
        let data = {
          id: item.id,
          url: 'resultsinfo',
          name: '科技成果',
        }
        data.amType = item.amType
        this.$router.push({
          path: '/resultDetails',
          query:data
        });
      },
      //切换分类筛选
      changindex(index) {
        this.cindex = index
        this.dataList = []
        this.getList()

      },

      //查看评论
      getViews() {
        if(this.gettype=='park'){
          this.aId = this.dataInfo.proId
        }
        if(this.gettype=='service'){
          this.aId=this.dataInfo.sedId
        }
        console.log(this.aId,'this.aId')
        let data = {
          tableId: this.aId
        }
         console.log(data,'this.aId')
        postRequest(api.selectViews, data).then(res => {
          res.page.list.forEach(item => {
            item.ischeck = false
          })
          this.messageList = res.page.list
        })
      },

      stbView() {
        if (sessionStorage.getItem('isLogin') != 1) {
          this.$message({
            type: "error",
            message: "请前往登陆！",
          });
          return
        }
        if (!this.ureContent) {
          this.$message({
            type: "error",
            message: "请发表评论！",
          });
          return
        }
        if(this.gettype=='park'){
          this.aId = this.dataInfo.proId
        }
        if(this.gettype=='service'){
          this.aId=this.dataInfo.sedId
        }
		if(this.amType==0){
		  this.aId=this.dataInfo.projectId
		}
        let data = {
          tableId: this.aId,
          ureContent:this.ureContent,
        }

        postRequest(api.viewsSave, data).then(res => {
          if(res.code==0){
            this.$message({
              type: "success",
              message: "评论成功,请耐心等待工作人员回复！",
            });
          }else{
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        })
        this.ureContent = ''
        this.getViews()
      },

      stbViews(nums) {
        if (sessionStorage.getItem('isLogin') != 1) {
          this.$message({
            type: "error",
            message: "请前往登陆！",
          });
          return
        }
        if (!this.ureContent1) {
          this.$message({
            type: "error",
            message: "请发表评论！",
          });
          return
        }
        if(this.gettype=='park'){
          this.aId = this.dataInfo.proId
        }
        if(this.gettype=='service'){
          this.aId=this.dataInfo.sedId
        }
		if(this.amType==0){
		  this.aId=this.dataInfo.projectId
		}
        let data = {
          tableId: this.aId,
          ureNums: nums ? nums : '',
          ureContent:this.ureContent1,
        }
        postRequest(api.viewsSave, data).then(res => {
          if(res.code==0){
            this.$message({
              type: "success",
              message: "评论成功,请耐心等待工作人员回复！",
            });
          }else{
            this.$message({
              type: "error",
              message: res.msg,
            });
          }

        })
        this.ureContent1 = ''
        this.messageList.forEach(row => {
           row.ischeck = false
        })
        this.getViews()
      },

      //回复评论
      changeMsg(item) {
        this.ureContent1 = ''
        this.messageList.forEach(row => {
          if (item.ureNums == row.ureNums) {
            row.ischeck = !row.ischeck
          } else {
            row.ischeck = false
          }
        })
      },




      //获取字典列表
      getDetails() {
        let data = {}
        if (this.amType == 1 || this.amType == 2 || this.amType == 3) {
          data.adId = this.aId
          postRequest(api.getadDetails, data).then(res => {
            this.dataInfo = res.mhAchievementDetail
            this.imgUrlList = this.dataInfo.filesList
            this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
            this.isDetails = true
            if(sessionStorage.getItem('isLogin') == 1&&this.dataInfo.adId) {
              this.getViews()
            }

          })
        }
        if (this.amType == 4) {
          data.cdId = this.aId
          postRequest(api.getcdDetails, data).then(res => {
            this.dataInfo = res.mhCopyrightDetailVo
            this.imgUrlList = this.dataInfo.filesList
            this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
            this.isDetails = true
            if(sessionStorage.getItem('isLogin') == 1&&this.dataInfo.adId) {
              this.getViews()
            }
          })
        }
        if (this.amType == 5) {
          data.tsId = this.aId
          postRequest(api.gettsDetails, data).then(res => {
            this.dataInfo = res.mhTechnicalSecretVo
            this.imgUrlList = this.dataInfo.filesList
            this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
            this.isDetails = true
            if(sessionStorage.getItem('isLogin') == 1&&this.dataInfo.adId) {
              this.getViews()
            }
          })
        }

        if (this.amType == 7) {
          data.oaId = this.aId
          postRequest(api.getodDetails, data).then(res => {
            this.dataInfo = res.mhOtherAchievementsVo
            this.imgUrlList = this.dataInfo.filesList
            this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
            this.isDetails = true
            if(sessionStorage.getItem('isLogin') == 1&&this.dataInfo.adId) {
              this.getViews()
            }

          })
        }
        if (this.gettype == 'service') {
          data.aId = this.aId
          postRequest(api.selectByAchievementId, data).then(res => {
            this.dataInfo = res.data
            this.imgUrlList = this.dataInfo.files
            this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
            this.isDetails = true
            if(sessionStorage.getItem('isLogin') == 1&&this.dataInfo.sedId) {
              this.getViews()
            }
          })
        }
        if (this.gettype == 'park') {
          data.aId = this.aId
          postRequest(api.selectproductId, data).then(res => {
            this.dataInfo = res.data
            this.imgUrlList = this.dataInfo.files
            this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
            this.isDetails = true
            if(sessionStorage.getItem('isLogin') == 1&&this.dataInfo.proId) {
              this.getViews()
            }
          })
        }



      },
      //切换选项
      changeindex(item) {
        this.isIndex = item
      },

      changeImg(item, index) {
        this.mainImgUrl = item.msrUrlStatic
        this.msrUrlPicture = item.msrUrlPicture
        this.imgActiveIndex = index
      },
      imgLeft() {
        if (this.imgActiveIndex > 0) {
          this.imgActiveIndex-- // 索引值-1
          this.imgUrlList.forEach((item, index) => { // 循环小图,通过判断索引值赋值给大图
            if (this.imgActiveIndex === index) {
              this.mainImgUrl = item.msrUrlStatic
            }
          })
        }
        if (this.imgActiveIndex >= 4) {
          var index = 0
          const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
            if (index < 33) { // 移动次数(33次)
              this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
              index++
              return
            } else {
              window.clearInterval(temp) // 移动完清除定时器
            }
          }, 10)
        }
      },
      imgRight() {
        if (this.imgActiveIndex < this.imgUrlList.length - 1) {
          this.imgActiveIndex++
          this.imgUrlList.forEach((item, index) => {
            if (this.imgActiveIndex === index) {
              this.mainImgUrl = item.msrUrlStatic
            }
          })
          if (this.imgActiveIndex >= 5) {
            this.allDistance = -66 * (this.imgActiveIndex - 4)
            var index = 0
            const temp = window.setInterval(() => {
              if (index < 33) {
                this.imgDistance -= 2 // 每次向右移动的距离
                index++
                return
              } else {
                window.clearInterval(temp)
              }
            }, 10)
          }
        } else if (this.imgActiveIndex === this.imgUrlList.length - 1) { // 到达最后一张图片，再点击跳转回第一张
          this.imgActiveIndex = 0;
          this.mainImgUrl = this.imgUrlList[0].msrUrlStatic
          var index = 0
          const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
            if (index < Math.abs(this.allDistance / 2)) { // 取绝对值再除
              this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
              index++
              return
            } else {
              window.clearInterval(temp) // 移动完清除定时器
            }
          }, 1)
        }
      },

      //查看详情
      tourl(data) {
        this.$router.push({
          path: '/tecDetails',
          query: {

          },
        });
      },
      changeleave(type) {
        if (type == 1) {
          this.dialogVisible = true
        } else {
          this.isdialog = true
        }
      },
      handelClose() {
        this.dialogVisible = false
        this.isdialog = false
      },
      onSubmit() {
        this.isstatus = 2
      }
    }
  }
</script>

<style lang="less" scoped>
  .Img_ul {
    position: relative;
    display: flex;
    left: 36px;
    width: 322px;
    height: 50px;
    overflow: hidden;
    list-style: none;
  }

  .Img_li {
    float: left;
    margin: 0 8px;
    cursor: pointer;
  }

  .img_activeBorder {
    border: 3px solid #0dcc73;
  }

  .infodiv {
    width: 1190px;
    margin: 0 auto;
  }

  .contimg {
    width: 300px;
    height: 300px;
  }

  .swiper-container {
    height: 300px;
    width: 100%;
    margin: 0 auto;

    .simg {
      width: 100%;
      height: 100%;
    }
  }

  .bassMess {
    position: relative;
    overflow: hidden;
    border-radius: 0 0 5px 5px;
    margin-bottom: 20px;
    width: 65%;
  }

  .leftContent {
    width: 100%;
    margin-bottom: 20px;

    .counter-box {
      background: #fff;
      border-radius: 5px;
      position: relative;
      box-shadow: 0px 5px 14px 0 #ddd;
      color: #323232;
      min-height: 300px;
    }


    .tit_small {
      color: #999;
    }

    .company {
      color: #ff1b1b;
    }

    .levelMess {
      width: 100%;
      overflow: hidden;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .levelTit {
        position: relative;
        height: 60px;

        p {
          width: 100%;
          height: 30px;
          line-height: 30px;
          margin: 20px 0;
          font-size: 22px;
          color: #323232;
          border-left: 6px solid #1277fd;
          padding-left: 26px;
          z-index: 2;
          vertical-align: top;
          position: absolute;
          left: 0;
          bottom: 0;
        }

        .colorBox {
          height: 10px;
          width: 68px;
          background: #c0d4fb;
          z-index: 1;
          position: absolute;
          left: 29px;
          bottom: 20px;
        }
      }
    }

    .showview {
      margin: 10px 30px;
    }

    .showview div[class*=col-sm] {
      color: #323232;
      font-size: 15px;
      padding: 8px 0;
    }

    .showview .title {
      color: #999;
    }

    .showview span {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .tit {
    margin-bottom: 10px;
  }



  .btnBox {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .ctitle {
    font-size: 16px;
    color: #000;
    margin: 20px 0;
  }

  .cbtn {
    float: right;
    margin-top: 10px;
  }

  .relate>div {
    border: 1px solid #ededed;
    margin-bottom: 40px;
  }

  .allTit {
    width: 100%;
    background: #f8f8f8;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    font-weight: normal;
    padding: 0 20px;
  }

  .relate {
    width: 290px;
  }

  .relate ul {
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 0;
  }

  .infocont {
    display: flex;
    justify-content: space-between;
  }

  .navTitle {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #00A0E9;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;

    p {
      font-size: 14px;
      padding: 0 20px;
      cursor: pointer;
    }

    .acitve {
      background: #00A0E9;
      color: #fff;
      font-size: 14px;
      padding: 0 20px;
    }

  }

  .topdiv {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .topimg {
      margin-right: 20px;
    }

    .fleft {
      color: #666;
      line-height: 28px;
      margin-bottom: 5px;
      color: #00A0E9;
      font-size: 20px;
    }

    .messTable {
      background-color: #f3f3f3;
      padding: 20px;
      width: 100%;
      position: relative;
      height: 400px;
      overflow: auto;

      .mdiv {
        margin-bottom: 20px;
      }

      img {
        width: 20px;
        height: 22px;
        position: relative;
        top: 4px;
      }
    }

    .btnBox {
      margin-top: 20px;
    }

  }

  .statusdiv {
    width: 70px;
    background: #00A0E9;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    margin: 10px 0;
    display: inline-block;
  }

  .mldiv {
    margin-bottom: 20px;

    span {
      font-size: 20px;
      color: #333;
    }
  }

  .mdiv {
    .company {
      font-size: 16px;
      color: #333;
    }
  }

  .msgdiv {
    .info {
      width: 80%;
      margin: 0 auto;
      display: flex;

      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }

    .infobtn {
      width: 80%;
      margin: 10px auto 0;
      text-align: right;
    }
  }

  .msgdivsend {
    .info {
      width: 80%;
      margin: 0 auto 10px;
      display: flex;
      border-bottom: 1px solid #999;

      img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }

      .msgtextdiv {
        width: 1190px;

        .name {
          font-size: 13px;
          color: #222;
        }

        .msg {
          padding-top: 10px;
          font: 16px;
          color: #222;
        }

        .time {
          display: flex;
          justify-content: space-between;
          padding-top: 4px;
          margin-bottom: 10px;

          span {
            color: #9195a3;
            font-size: 14px;
          }
        }

        .msgcont {
          text-align: right;
          margin-bottom: 10px;

          .el-button {
            margin-top: 10px;
          }
        }

      }
    }
  }

  .news_list {
    overflow: hidden;
    border-top: 1px solid #00A0E9;
    border-left: 1px solid #00A0E9;
    // border-bottom: 1px solid #00A0E9;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 200px;

    .cur {
      color: #fff;
      background-color: #00A0E9;
    }

    span {
      font-size: 16px;
      color: #000;
      background: #fff;
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      padding-left: 0;
      border: none;
      position: relative;
      cursor: pointer;
      border-right: 1px solid #00A0E9;
      border-bottom: 1px solid #00A0E9;
    }
  }

  .datacont {
    overflow-y: auto;

    .linedata {
      // border-bottom: 1px solid #ddd;
          cursor: pointer;
          /* display: flex; */
          align-items: center;
          /* justify-content: flex-start; */
          overflow-x: auto;
          white-space: nowrap;
          width: 80%;
          margin: 0 auto;

    }

    .activecur {
      background-color: #f0f8fc;
      font-weight: bold;
      color: #333;
    }

    .icontext {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #00A0E9;
      color: #fff;
      display: inline-block;
      margin-right: 20px;

    }

    .iname {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
    }


  }
  .mbox{

    display: flex;justify-content:space-between;
    .mdiv1{
       flex:1;
    }
  }
  .infodiv1{
      background-color: #a6a9ad;
      color: #fff;
      border: none;
  }
  .linedata div{
      text-align: center;
      display: flex;
      justify-content: space-between;
      border: 1px solid #ddd;
          border-right: 0;
          border-top: 0;
  }
  .linedata div span{
      text-align: center;
      display: inline-block;
       width: 25%;
           border-right: 1px solid #ddd;
           padding: 10px;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;

  }
  .levelTit {
    position: relative;
    height: 60px;

    p {
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin: 20px 0;
      font-size: 22px;
      color: #323232;
      border-left: 6px solid #1277fd;
      padding-left: 26px;
      z-index: 2;
      vertical-align: top;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .colorBox {
      height: 10px;
      width: 68px;
      background: #c0d4fb;
      z-index: 1;
      position: absolute;
      left: 29px;
      bottom: 20px;
    }
  }
</style>
